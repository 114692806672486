$dim-page-max-width: 1000px;

$dim-breakpoint-xs: 450px;
$dim-breakpoint-sm: 550px;

$dim-breakpoint-checkout: 600px;

$dim-breakpoint-md: 800px;

$dim-breakpoint-md-lg: 900px;

$dim-breakpoint-lg: 1200px;

$dim-checkout-max-width: 600px;

@mixin breakpoint-up-xs() {
    @media (min-width: $dim-breakpoint-xs) {
        @content;
    }
}

@mixin breakpoint-up-sm() {
    @media (min-width: $dim-breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-up-md() {
    @media (min-width: $dim-breakpoint-md) {
        @content;
    }
}

@mixin breakpoint-up-lg() {
    @media (min-width: $dim-breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-up-checkout() {
    @media (min-width: $dim-breakpoint-checkout) {
        @content;
    }
}

@import "../../scss/variables";

.checkout {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10rem 2rem 2rem;
    // min-height: 100%;

    &__title {
        @include checkout-element;
        text-align: center;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__subtitle {
        @include checkout-element;
        text-align: center;
        font-size: 1.6rem;
    }

    &__description {
        @include checkout-element;
        padding: 2rem;
        font-size: 1.6rem;
        text-align: center;
    }
}
